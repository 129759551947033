import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';

/**
 * get one supplier
 * @param {String, Number} supplierID
 */
export const getSupplier = async (supplierID) => {
	//console.log("getSupplier supplierID:",supplierID);
	if (isNil(supplierID)) return;
	// try {
	const {
		data: { GetSupplier }
	} = await apollo.query({
		query: gql`
				query ($SupplierId: ID!) {
					GetSupplier(SupplierId: $SupplierId) ${columnSupplier}
				}
			`,
		variables: {
			SupplierId: parseInt(supplierID)
		},
		fetchPolicy: 'no-cache'
	});

	return {
		id: parseInt(supplierID),
		...GetSupplier
	};
	// } catch (e) {
	// 	console.log({ e });
	// }
};

export const getSuppliers = async ({ name, type, ForJobRequest = false, ForActiveProject = false }) => {
	const { data } = await apollo.query({
		query: gql`
			query ($SupplierName: String, $Type: Int, $ForJobRequest: Boolean, $ForActiveProject: Boolean) {
				GetSuppliers(SupplierName: $SupplierName, Type: $Type, ForJobRequest:$ForJobRequest,ForActiveProject: $ForActiveProject)  ${columnSupplier}
			}
		`,
		variables: {
			SupplierName: name || null,
			Type: !isNil(type) ? parseInt(type) : null,
			ForJobRequest,
			ForActiveProject
		},
		fetchPolicy: 'no-cache'
	});

	return data.GetSuppliers;
};

export const addSupplier = async (supplier) => {
	try {
		const { data } = await apollo.mutate({
			mutation: gql`
				mutation ($SupplierName: String!, $Type: Int, $IsGreen: Boolean) {
					AddSupplier(SupplierName: $SupplierName, Type: $Type, IsGreen: $IsGreen)  ${columnSupplier}
				}
			`,
			variables: {
				SupplierName: supplier.name,
				Type: parseInt(supplier.type),
				IsGreen: supplier.isGreen ? supplier.isGreen : false
			}
		});

		return data.AddSupplier;
	} catch (e) {
		throw new Error(e);
	}
};

export const addAddressSupplier = async ({ id, supplier, address }) => {
	const mutation = gql`
		mutation ($UpdatedSupplier: SupplierInput!, $UpdatedSupplierAddress: AddressInput!, $SupplierId: ID!) {
			UpdSupplier(UpdatedSupplier: $UpdatedSupplier, UpdatedSupplierAddress: $UpdatedSupplierAddress, SupplierId: $SupplierId)  ${columnSupplier}
		}
	`;
	try {
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				UpdatedSupplier: supplier,
				UpdatedSupplierAddress: address,
				SupplierId: parseInt(id)
			}
		});

		return data.UpdSupplier;
	} catch (e) {
		throw new Error(e);
	}
};

export const updateSupplier = async ({ id, supplier, address }) => {
	try {
		const mutation = gql`
			mutation ($UpdatedSupplier: SupplierInput!, $UpdatedSupplierAddress: AddressInput!, $SupplierId: ID!) {
				UpdSupplier(UpdatedSupplier: $UpdatedSupplier, UpdatedSupplierAddress: $UpdatedSupplierAddress, SupplierId: $SupplierId)  ${columnSupplier}
			}
		`;
		const {
			data: { UpdSupplier }
		} = await apollo.mutate({
			mutation,
			variables: {
				SupplierId: parseInt(id, 10),
				UpdatedSupplier: supplier,
				UpdatedSupplierAddress: address
			}
		});

		return {
			id: parseInt(id),
			...UpdSupplier
		};
	} catch (e) {
		console.log({ e });
	}
};

export const deleteSupplier = async ({ id }) => {
	try {
		const mutation = gql`
			mutation ($SupplierId: ID!) {
				DelSupplier(SupplierId: $SupplierId)
			}
		`;

		const {
			data: { DelSupplier }
		} = await apollo.mutate({
			mutation,
			variables: {
				SupplierId: +id
			}
		});

		return DelSupplier;
	} catch (e) {
		console.error({ e });
	}
};

export const transferGreenSupplierToProject = async (id) => {
	try {
		const mutation = gql`
			mutation ($SupplierId: ID!) {
				TransferGreenSupplierToProject(SupplierId: $SupplierId)
			}
		`;
		const { data } = await apollo.mutate({
			mutation,
			variables: {
				SupplierId: parseInt(id)
			}
		});

		return data.TransferGreenSupplierToProject;
	} catch (e) {
		console.error({ e });
	}
};

export const getGreenSuppliers = async ({ name, state, type }) => {
	const { data } = await apollo.query({
		query: gql`
			query ($CountryName: String, $StateName: String, $Type: Int) {
				GetGreenSuppliers(CountryName: $CountryName, StateName: $StateName, Type: $Type) {
					id
					createdAt
					webSite
					name
					externalId
					type
					addressId
					labelOrigin
					labelXid
					user {
						id
						name
						firstName
						fullName
						picture
					}
					address {
						id
						state
						street
						number
						box
						lat
						lng
						city
						country
						countryCode
						countryKey
						zip
					}
				}
			}
		`,
		variables: {
			CountryName: name || null,
			StateName: state || null,
			Type: !isNil(type) ? parseInt(type) : null
		},
		fetchPolicy: 'no-cache'
	});

	return data.GetGreenSuppliers;
};

export const getGreenSuppliersCountries = async () => {
	const { data } = await apollo.query({
		query: gql`
			query {
				GetGreenSuppliersCountries
			}
		`,
		variables: {},
		fetchPolicy: 'no-cache'
	});

	return data.GetGreenSuppliersCountries;
};

export const getGreenSuppliersCountriesStates = async (name) => {
	const { data } = await apollo.query({
		query: gql`
			query ($CountryName: String!) {
				GetGreenSuppliersCountriesStates(CountryName: $CountryName)
			}
		`,
		variables: {
			CountryName: name || ''
		},
		fetchPolicy: 'no-cache'
	});

	return data.GetGreenSuppliersCountriesStates;
};

export const getSuppliersCountries = async () => {
	const GET_SUPPLIERS_COUNTRIES = gql`
		query {
			GetSuppliersCountries
		}
	`;
	const {
		data: { GetSuppliersCountries }
	} = await apollo.query({
		query: GET_SUPPLIERS_COUNTRIES
	});

	return GetSuppliersCountries;
};

export const getSuppliersStates = async (countryName) => {
	const GET_SUPPLIERS_STATES = gql`
		query ($countryName: String) {
			GetSuppliersStates(CountryName: $countryName)
		}
	`;
	const {
		data: { GetSuppliersStates }
	} = await apollo.query({
		query: GET_SUPPLIERS_STATES,
		variables: {
			countryName
		}
	});

	return GetSuppliersStates;
};

export const addUpdSupplierBankAccount = async (bankId, bank) => {
	const mutation = gql`
		mutation ($bankId:ID!,$bank:SupplierBankAccountInput!) {
			# SupplierBankAccountId:ID!,SupplierBankAccountInput:SupplierBankAccountInput!
			AddUpdSupplierBankAccount(SupplierBankAccountId: $bankId, SupplierBankAccountInput: $bank) ${columnSupplierBank}
		}
	`;
	const {
		data: { AddUpdSupplierBankAccount }
	} = await apollo.mutate({
		mutation,
		variables: {
			bankId: +bankId,
			bank
		}
	});

	return AddUpdSupplierBankAccount;
};

export const delSupplierBankAccount = async (bankId) => {
	const MUTATION_DEL_SUPPLIER_BANK_ACCOUNT = gql`
		mutation ($bankId: ID!) {
			DelSupplierBankAccount(SupplierBankAccountId: $bankId)
		}
	`;

	const {
		data: { DelSupplierBankAccount }
	} = await apollo.mutate({
		mutation: MUTATION_DEL_SUPPLIER_BANK_ACCOUNT,
		variables: {
			bankId: +bankId
		}
	});

	return DelSupplierBankAccount;
};

export const getSupplierBankAccount = async (bankId) => {
	const QUERY_GET_SUPPLIER_BANK_ACCOUNT = gql`
            query ($bankId: ID!) {
                GetSupplierBankAccount (SupplierBankAccountId: $bankId) ${columnSupplierBank}
            }
        `;

	const {
		data: { GetSupplierBankAccount }
	} = await apollo.query({
		query: QUERY_GET_SUPPLIER_BANK_ACCOUNT,
		variables: {
			bankId: +bankId
		},
		fetchPolicy: 'no-cache'
	});

	return GetSupplierBankAccount;
};

export const getSupplierBankAccounts = async (supplierId) => {
	const QUERY_GET_SUPPLIER_BANK_ACCOUNTS = gql`
            query ($supplierId: ID) {
                GetSupplierBankAccounts (SupplierId: $supplierId) ${columnSupplierBank}
            }
        `;

	const {
		data: { GetSupplierBankAccounts }
	} = await apollo.query({
		query: QUERY_GET_SUPPLIER_BANK_ACCOUNTS,
		variables: {
			supplierId: +supplierId
		},
		fetchPolicy: 'no-cache'
	});

	return GetSupplierBankAccounts;
};

const columnSupplier = `{
	id
	licenseId
	name
	webSite
	externalId
	companyType
	email
	vat
	vatPercent
	phone
	info
	addressId
	contactName
	contactFunction
	nationalRegistryNumber
	type
	vatRate
	isGreen
	taxRegistrationNumber
	accountPhone
	accountEmail
	companyTypeName
	companyRegistrationNumber
	lastPoNumber
	typeName
	urssafName
	urssafFirstName
	urssafCode
	nafCode
	healthService
	address {
		id
		state
		street
		number
		box
		lat
		lng
		city
		country
		countryCode
		countryKey
		zip		
	}
	supplierBankAccounts {
		id
		bankName
		currency
		exchangeRate
		iban
		bic
	}
}`;

const columnSupplierBank = `{
	id
	supplierId
	bankName
	currency
	exchangeRate
	iban
	bic
}`;
